<template>
  <div class="additionalSettlementPageBox page-info-content">
    <Details-page
      :details-title="'基本信息'"
      :detail-obj="detailObj"
      :details-item-arr="detailsItemArr"
    />
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage.vue'
import { invoiceIssureInvoiceDetail } from '@/api/shoppingMall'
export default {
  components: { DetailsPage },
  data() {
    return {
      detailObj: {},
      detailsItemArr: [
        { label: '业务编码', value: 'busiNo' },
        { label: '发票代码', value: 'invoiceCode' },
        { label: '发票号码', value: 'invoiceNo' },
        { label: '发票金额', value: 'invoiceAmt' },
        { label: '发票抬头名称', value: 'invoiceTitleName' },
        { label: '社会信用代码', value: 'invoiceUnicode' },
        { label: '开户行账号', value: 'invoiceBankCode' },
        { label: '开户行名称', value: 'invoiceBankName' },
        { label: '发票类型', value: 'invoiceType', child: [{ val: '增值税普通发票', id: '00' }, { val: '增值税专用发票', id: '01' }] },
        { label: '发票状态', value: 'receiveType', child: [{ val: '电子发票', id: '00' }, { val: '纸质发票', id: '01' }] },
        { label: '接收方式', value: 'state', child: [{ val: '开票中', id: '01' }, { val: '已开票', id: '02' }] },
        { label: '开票方会员名称', value: 'openCmpName' },
        { label: '发票开据日期', value: 'openTime' },
        { label: '快递单号', value: 'courierNumber' },
        { label: '收件地址', value: 'recAddr' },
        { label: '电子邮箱', value: 'recMail' },
        { label: '联系人姓名', value: 'recName' },
        { label: '联系人电话', value: 'recTel' },
        { label: '发票附件', value: 'fdfsName', link: 'fdfsUrl' }
      ]
    }
  },
  mounted() {
    if (this.$route.query.id || this.$route.query.busiId) {
      invoiceIssureInvoiceDetail({ id: this.$route.query.id || '', busiId: this.$route.query.busiId || '' }, (res) => {
        this.detailObj = { ...res.data }
      })
    }
  }
}
</script>

<style lang="scss">
.additionalSettlementPageBox {
  .differential {
    padding: 30px 32px;
  }
  .cancelButton {
    margin-left: 32px;
  }
  .table {
    width: calc(100% - 64px);
    margin-left: 32px;
    margin-bottom: 32px;
    .el-table__header .el-checkbox {
      display: none;
    }
  }
  .confirmationSettlement {
    margin: 0 32px;
    .el-descriptions-item__cell {
      padding-bottom: 24px;
    }
    .el-descriptions-item__content{
      width: 100%;
    }
    .labelClassName {
      flex: 0 0 132px;
      display: flex;
      align-items: center;
    }
    .el-input {
      width: 86%;
    }
    .icon {
      font-size: 14px;
      color: #666;
      margin-left: 12px;
    }
  }

  .el-descriptions {
    padding: 0 !important;
  }
  .settlementTitle {
    display: flex;
    align-items: flex-end;
    margin-bottom: 32px;
    span {
      color: #666;
      font-size: 14px;
      margin-left: 18px;
    }
  }
}
</style>
